import { CustomerCareEntity, ListEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.customer_care.index): Promise<ListEntity<CustomerCareEntity>> => instanceAxios.get('/customer-cares', { params })

const show = async (id: string): Promise<any> => instanceAxios.get(`customer-cares/${id}`)

const mapStore = async (campaign: string): Promise<any> => instanceAxios.post(`customer-cares/map-store`, { campaign })

const user_statistic = (params: ApiRequest.customer_care.user_stats): Promise<any> =>
  instanceAxios.get('customer-cares/statistic/user', {
    params
  })

const store_statistic = (params: ApiRequest.customer_care.store_stats): Promise<any> =>
  instanceAxios.get('customer-cares/statistic/store', {
    params
  })

export default {
  statistic: {
    user: user_statistic,
    store: store_statistic
  },

  mapStore,
  index,
  show
}
