import { ArrowDropDown } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { COLORS } from 'utils'

interface Props {
  label: string
  children: any
}
export function Section({ label, children }: Props) {
  const [expand, setExpand] = useState(false)
  return (
    <Stack direction="column" sx={{ mb: 2, borderRadius: '4px', overflow: 'hidden' }}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={() => {
          setExpand(!expand)
        }}
        sx={{
          backgroundColor: '#E5E7EB',
          px: 1,
          py: 1.2,
          '&:hover': {
            cursor: 'pointer',
            opacity: 0.8
          }
        }}
      >
        <Typography
          style={{
            fontSize: '14px',
            color: COLORS.DarkCharcoal,
            fontWeight: '500',
            flex: 1
          }}
        >
          {label}
        </Typography>
        <ArrowDropDown />
      </Stack>
      {expand && <Stack sx={{ backgroundColor: '#fff', px: 2, py: 1 }}>{children}</Stack>}
    </Stack>
  )
}
