import { Stack } from '@mui/material'
import { Button, Tabs } from 'components'
import { useSelector } from 'hooks'
import { EnumImportCategory } from 'models'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { importExcelActions } from 'store/slices/import_excel'
interface Props {
  only_preview?: boolean
  onImport?: (file: any) => void
}
export function Statistic({ onImport, only_preview }: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { data, category, file, config } = useSelector((x) => x.import_excel)

  const errorItems = data?.filter((item: any) => !config?.isValidItem?.(item)) ?? []
  const validItems = data?.filter((item: any) => config?.isValidItem?.(item)) ?? []

  const TabItems: { label: string; value: EnumImportCategory }[] = [
    {
      label: `Toàn bộ (${data?.length ?? 0})`,
      value: EnumImportCategory.ALL
    },
    {
      label: `Hợp lệ (${validItems.length})`,
      value: EnumImportCategory.VALID
    },
    {
      label: `Không hợp lệ (${errorItems.length})`,
      value: EnumImportCategory.INVALID
    }
  ]
  return (
    <Stack sx={{ mx: 5, my: 1 }}>
      {data && data.length > 0 && (
        <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
          <Tabs
            data={TabItems}
            value={category}
            onSelect={(a) => {
              dispatch(importExcelActions.selectCategory(a as EnumImportCategory))
            }}
          />

          {!only_preview && <Button type="primary" label={loading ? 'Đang nhập...' : 'Nhập dữ liệu'} onClick={() => onImport?.(file)} />}
        </Stack>
      )}
    </Stack>
  )
}
