import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { images } from 'assets'
import { showToast } from 'components/GlobalMessage'
import { AttachmentEntity } from 'models'
import React from 'react'
import { COLORS, formatDateEn, formatDatetime } from 'utils'

export function ImportedFile({ data }: { data: AttachmentEntity[] }) {
  return (
    <Stack direction={'column'} sx={{ mt: 2, width: '100%' }}>
      <Typography style={{ fontSize: '12px', color: COLORS.DarkCharcoal }}>{`Các file đã tải lên: ${data.length}`}</Typography>
      <Stack
        width={'100%'}
        sx={{ maxHeight: '200px', overflowY: 'scroll', px: 1, py: 1, borderColor: COLORS.AuroMetalSaurus + '88', borderWidth: '1px', borderRadius: '4px' }}
      >
        {data.map(({ title, created_by, created_at, link }: AttachmentEntity) => (
          <Stack
            direction={'row'}
            sx={{
              borderTopWidth: '1px',
              borderTopColor: COLORS.AuroMetalSaurus,
              alignItems: 'center',
              py: 1
            }}
          >
            {/* <img style={{ width: '20px', height: '20px', backgroundColor: 'yellow' }} src={images.sheets} /> */}
            <Stack direction={'column'} sx={{ display: 'flex', flex: 1 }}>
              <Tooltip title={'Tải file này xuống'} placement="right">
                <Box
                  onClick={() => {
                    window.location.href = link
                    showToast({
                      content: `Tải file thành công`,
                      type: 'success'
                    })
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '12px',
                      cursor: 'pointer',
                      color: COLORS.CelticBlue,
                      textDecorationLine: 'underline',
                      fontWeight: '500',
                      wordBreak: 'break-word'
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Tooltip>
              <Typography style={{ fontSize: '10px', color: COLORS.nickel }}>{created_by?.name ?? ''}</Typography>
              <Typography style={{ fontSize: '10px', color: COLORS.nickel }}>{formatDatetime(created_at)}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
