import { Box, Card, Tooltip } from '@mui/material'
import React, { FC } from 'react'
/* eslint-disable consistent-return */
import { Button, Stack, Typography } from '@mui/material'
import { UploadButton } from 'components'
import { useSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { formatBytes } from 'utils'
import { importExcelActions } from 'store/slices/import_excel'

interface Props {
  onSelectFile: (e: File) => void
}
export function FileInput({ onSelectFile }: Props) {
  const { file, config, is_preview } = useSelector((x) => x.import_excel)
  const dispatch = useDispatch()

  return (
    <Box
      sx={{
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {!file ? (
        <UploadButton onChange={onSelectFile} />
      ) : (
        <Card
          sx={{
            padding: 1,
            borderRadius: 2,
            alignSelf: 'baseline',
            width: '200px'
          }}
        >
          <Stack direction="column" spacing={0.3}>
            <Tooltip title={file.name}>
              <Typography sx={{ fontSize: '12px', color: '#333', cursor: 'pointer' }}>
                {file.name?.substring(0, 26) + (file.name?.length <= 26 ? '' : '...')}
              </Typography>
            </Tooltip>
            <Typography style={{ fontSize: '10px', color: '#999' }}>{file.size && Number(file.size) ? formatBytes(file.size) : ''}</Typography>

            <Stack direction="row" alignItems="center" spacing={3}>
              <Button
                component="label"
                variant="outlined"
                sx={{ fontSize: '14px', mt: 1.5 }}
                onClick={() => {
                  dispatch(importExcelActions.setFile(undefined))
                  dispatch(importExcelActions.setData([]))
                }}
              >
                Chọn file khác
              </Button>
            </Stack>
          </Stack>
        </Card>
      )}
    </Box>
  )
}
