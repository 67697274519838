import { Button, showApiError, showToast } from 'components'
import { useSelector } from 'hooks'
import { isString } from 'lodash'
import React, { useCallback, useState } from 'react'
import Api from 'services'

export const TemplateFile = () => {
  const { config, whilelist_columns } = useSelector((x) => x.import_excel)
  const { template_code } = config ?? {}
  const [enable, setEnable] = useState<boolean>(true)
  const handleDownload = useCallback(async () => {
    if (!enable) {
      return
    }
    if (!template_code) {
      showToast({
        content: 'Chưa cấu hình tệp template',
        type: 'warning'
      })
      return
    }
    setEnable(false)
    setTimeout(() => {
      setEnable(true)
    }, 10000)
    Api.excel_template
      .getFile({
        template_code,
        keys: whilelist_columns[template_code]
      })
      .then((res) => {
        console.log('Res: ', res)

        var link = res?.data?.data
        if (!link || !isString(link)) {
          showToast({
            content: 'Không thể tải xuống file',
            type: 'warning'
          })
          return
        }
        showToast({
          content: 'Tải template thành công',
          type: 'success'
        })
        window.location.href = link
      })
      .catch((e) => {
        showApiError(e)
      })
  }, [template_code, enable, whilelist_columns])

  return (
    <Button
      style={{
        paddingTop: '2px',
        paddingBottom: '2px'
      }}
      label={enable ? 'Tải template' : 'Đang tải file mẫu...'}
      type={enable ? 'primary' : 'default'}
      labelStyle={{
        fontSize: '12px'
      }}
      onClick={handleDownload}
    />
  )
}
