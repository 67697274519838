import { useSelector } from 'hooks'
import { EnumAction, EnumModule } from 'models'
import React, { ReactNode } from 'react'

export function PermissionWrapper({ children, module, action }: { children: ReactNode; module: EnumModule; action: EnumAction }) {
  const { user } = useSelector((x) => x.profile)
  const has_permission = user?.permissions?.find((item) => item.action === action && item.module === module) != null
  if (!has_permission) {
    return <div />
  }

  return children
}
