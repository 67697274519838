import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useSelector } from 'hooks'
import { EnumImportCategory, IColumn } from 'models'
import * as React from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export function Data() {
  const dispatch = useDispatch()
  const { data, category, config, template, whilelist_columns } = useSelector((x) => x.import_excel)

  const { template_code } = config ?? {}
  const isShowItem = useCallback(
    (item: any) => {
      if (!config?.isValidItem) {
        return true
      }
      const valid = config?.isValidItem(item)
      switch (category) {
        case EnumImportCategory.ALL:
          return true
        case EnumImportCategory.INVALID:
          return !valid
        case EnumImportCategory.VALID:
          return valid
        default:
          return false
      }
    },
    [category, config]
  )

  const getColumns = useCallback(() => {
    if (!template) {
      return []
    }
    const whilelist_keys = template_code ? whilelist_columns[template_code] : []
    const columns: GridColDef[] = template.columns
      .filter((item) => whilelist_keys?.includes(item.key))
      .map(({ key, label, required }: IColumn) => ({
        field: key,
        headerName: label + (required ? '(*)' : ''),
        width: 120
      }))

    return columns
  }, [template, template_code, whilelist_columns])

  const columns = getColumns()
  if (!config) {
    return <div />
  }
  const displayData = data?.filter((item: any) => isShowItem(item)) ?? []

  return (
    <Box sx={{ height: '500px', maxWidth: '1200px' }}>
      <DataGrid
        rows={displayData}
        columns={columns}
        getRowId={(item) => item.index}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[10, 50, 100]}
      />
    </Box>
  )
}
