/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Stack } from '@mui/material'
import { Button, DialogMain, UIDialogRef, showApiError, showToast } from 'components'
import { FormConfig, InputConfig } from 'models'
import React, { RefObject, createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { convertToParams } from 'store'
import { formActions } from 'store/slices/form'
import { instanceAxios } from 'utils'
import { InputWrapper } from './InputWrapper'

interface Props {
  config: FormConfig
  disabled?: boolean
  non_dialog?: boolean
  onComplete?: (a?: any) => void
}

export const Form = forwardRef<UIDialogRef, Props>(({ non_dialog, config, onComplete, disabled }, ref) => {
  const dispatch = useDispatch()
  const [error, setError] = useState<any>({})
  const [data, setData] = useState<any>(config.data ?? {})
  const inputs = config?.inputs ?? []
  const dialogRef: RefObject<UIDialogRef> = createRef()

  useEffect(() => {
    if (config.data) {
      setData(JSON.parse(JSON.stringify(config.data)))
    }
  }, [config.data])

  const fetchInitialData = useCallback(() => {
    if (!config.url_fetch) {
      setData(config.data ?? {})
      return
    }
    instanceAxios(config.url_fetch)
      .then((res) => {
        setData(res?.data?.data)
      })
      .catch()
  }, [config.url_fetch, config.data])

  useEffect(() => {
    if (config.form_id) {
      dispatch(formActions.setFormData({ form_id: config.form_id, data }))
    }
  }, [config.form_id, data, dispatch])

  useEffect(() => {
    if (config.form_id) {
      dispatch(formActions.setErrors({ form_id: config.form_id, error }))
    }
  }, [config.form_id, error, dispatch])

  useImperativeHandle(
    ref,
    () => ({
      show: (initalData?: any) => {
        if (initalData) {
          setData(initalData)
        } else {
          fetchInitialData()
        }

        dialogRef?.current?.show()
      },
      hide: () => {
        dialogRef?.current?.hide()
      }
    }),
    [dialogRef, fetchInitialData]
  )

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const handleChange = useCallback(
    (fields: any) => {
      setData({
        ...data,
        ...fields
      })
    },
    [data]
  )

  const validate = useCallback(() => {
    const validateRes: any = {}

    config.inputs.forEach(({ name, require, regex, type }) => {
      // Check require
      if (require) {
        if (data[name] === undefined || (type === 'input_text' && data[name].length === 0)) {
          validateRes[name] = 'Thiếu thông tin'
          return
        }
      }
      // Check format
      if (type === 'input_text') {
        if (data[name]?.length > 0 && regex) {
          const checker = new RegExp(regex, 'g')
          if (!checker.test(data[name])) {
            validateRes[name] = 'Không đúng định dạng'
          }
        }
      }
    })
    setError(validateRes)
  }, [config.inputs, data])

  useEffect(() => {
    validate()
  }, [validate])

  const removeUnusedFields = useCallback(
    (fields: any) => {
      const res: any = {}
      config.inputs.forEach(({ disabled, name }) => {
        if (!disabled) {
          res[name] = fields[name]
        }
      })
      return res
    },
    [config.inputs]
  )

  const handleClose = useCallback(() => {
    dialogRef.current?.hide()
  }, [dialogRef])

  const renderForm = useCallback(() => {
    return (
      <Grid container spacing={1} rowSpacing={1}>
        {inputs.map((item: InputConfig<any>) => (
          <Grid xs={item.grid_xs ?? 6} sx={{ py: '6px', px: '12px' }}>
            <InputWrapper
              disabled={disabled}
              {...item}
              value={data[item.name]}
              onChange={(a) => handleChange({ [item.name]: a })}
              error_msg={error?.[item.name]}
            />
          </Grid>
        ))}
      </Grid>
    )
  }, [inputs, error, disabled])

  const handleSubmit = useCallback(() => {
    if (error && Object.keys(error).length > 0) {
      // TODO: Show Toast
      showToast({
        content: 'Vui lòng điền thông tin hợp lệ',
        type: 'error'
      })
      return
    }
    let form = convertToParams(removeUnusedFields(data))
    if (config.customFormRequest) {
      form = config.customFormRequest(form)
    }

    instanceAxios({
      url: config.request_submit?.url,
      data: form,
      method: config.request_submit?.method
    })
      .then((res) => {
        handleClose()
        showToast({
          content: 'Thao tác thành công',
          type: 'success'
        })
        onComplete?.(res?.data?.data)
      })
      .catch((e) => {
        showApiError(e)
      })
  }, [config, data, error, handleClose, onComplete, removeUnusedFields])

  if (!config) {
    return <></>
  }

  const enableSubmit = error && Object.keys(error).length === 0

  return (
    <div>
      {!non_dialog && (
        <DialogMain label={config.header} ref={dialogRef} onClose={handleClose} onSubmit={enableSubmit ? handleSubmit : undefined} onCancel={handleClose}>
          {renderForm()}
        </DialogMain>
      )}
      {non_dialog && (
        <Stack>
          {renderForm()}
          {!disabled && config.request_submit && (
            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                label={config.btn_submit?.label ?? 'Lưu'}
                onClick={
                  enableSubmit
                    ? handleSubmit
                    : () => {
                        showToast({
                          content: 'Dữ liệu chưa hợp lệ',
                          type: 'warning'
                        })
                      }
                }
                type="primary"
              />
            </div>
          )}
        </Stack>
      )}
    </div>
  )
})
