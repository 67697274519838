import { Box, Chip, Typography } from '@mui/material'
import React, { FC } from 'react'
/* eslint-disable consistent-return */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Stack } from '@mui/material'
import { useSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { COLORS } from 'utils'
import { importExcelActions } from 'store/slices/import_excel'
interface Props {}
export const ColumnSelect: FC<Props> = ({}) => {
  const { template, whilelist_columns } = useSelector((x) => x.import_excel)
  var { code } = template ?? {}
  const dispatch = useDispatch()

  if (!code) {
    return <div />
  }
  const keys = code ? whilelist_columns[code] : []
  return (
    <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} sx={{}}>
      {code && template ? (
        template?.columns?.map(({ label, key, required }) => (
          <Chip
            label={
              <Typography style={{ fontSize: '12px', fontWeight: '400', color: keys?.includes(key) ? COLORS.white : COLORS.DarkCharcoal }}>
                {label + (required ? '(*)' : '')}
              </Typography>
            }
            size="small"
            onClick={() => code && dispatch(importExcelActions.selectColumn({ key, template: code }))}
            sx={{
              borderRadius: '2px',
              padding: '0px',
              margin: '2px',
              backgroundColor: keys?.includes(key) ? COLORS.LightSkyBlue : COLORS.bright_Gray,
              '&:hover': {
                opacity: 0.9
              }
            }}
          />
        ))
      ) : (
        <div />
      )}
    </Stack>
  )
}
