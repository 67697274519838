import { GridColDef } from '@mui/x-data-grid'
import { AttachmentEntity } from 'models/attachment'
import { EnumExcelTemplate, ExcelTemplate } from 'models/excel_template'

export enum EnumImportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportExcelState<T> {
  template?: ExcelTemplate
  file?: File
  is_preview?: boolean
  data?: T[]
  category?: EnumImportCategory
  config?: ImportExcelConfig

  whilelist_columns: Partial<Record<EnumExcelTemplate, string[]>>
}
export interface ImportExcelConfig {
  only_preview?: boolean
  isValidItem: (a: any) => boolean
  template_code?: EnumExcelTemplate
  submit_request: {
    url: string
    preview_param?: string
    params: object
    onComplete?: () => void
  }
  imported_file?: AttachmentEntity[]
}
