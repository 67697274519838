import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import _ from 'lodash'
import { ALL_MODULES, ALL_PERMISSIONS, PermissionEntity } from 'models'
import React, { useCallback } from 'react'
import { COLORS } from 'utils'

export interface Props {
  data: PermissionEntity[]
  onChange?: (a: PermissionEntity[]) => void
}
export function PermissionList({ data, onChange }: Props) {
  const modules = Object.values(_.groupBy(ALL_PERMISSIONS, 'module'))

  const isSelected = useCallback(
    (a: PermissionEntity) => {
      return data.find(({ module, action }) => a.module === module && a.action === action) !== undefined
    },
    [data]
  )

  const handleSelect = useCallback(
    (a: PermissionEntity) => {
      const selected = isSelected(a)
      if (selected) {
        onChange(data.filter(({ module, action }) => a.module !== module || a.action !== action))
        return
      }
      onChange([...data, a] as PermissionEntity[])
    },
    [data, isSelected, onChange]
  )

  return (
    <Card
      sx={{
        m: 2,
        borderRadius: 2,
        backgroundColor: '#fff'
      }}
    >
      {modules.map((items) => {
        if (items.length === 0) {
          return <></>
        }

        const module = ALL_MODULES.find(({ module }) => module === items[0].module)
        return (
          <Grid container sx={{ borderBottomWidth: '1px', borderBottomColor: COLORS.AuroMetalSaurus, px: 2 }}>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRightColor: COLORS.AuroMetalSaurus,
                borderRightWidth: '1px'
              }}
            >
              <Stack sx={{}}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: COLORS.DarkCharcoal,
                    fontWeight: '500'
                  }}
                >
                  {module?.label}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={9} sx={{}}>
              <Grid container>
                {items.map((item) => (
                  <Grid item xs={6}>
                    <Box
                      onClick={() => {
                        if (!onChange) {
                          return
                        }
                        handleSelect(item as PermissionEntity)
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    >
                      <Checkbox
                        disabled={onChange === undefined}
                        checked={isSelected(item as PermissionEntity)}
                        icon={<CheckBoxOutlineBlankRoundedIcon />}
                        checkedIcon={<CheckBoxRoundedIcon sx={{ color: onChange ? COLORS.CelticBlue : COLORS.AuroMetalSaurus }} />}
                      />
                      <Typography
                        style={{
                          fontSize: '13px',
                          color: COLORS.DarkCharcoal,
                          fontWeight: '400'
                        }}
                      >
                        {item.label ?? ''}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Card>
  )
}
