/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DistributeStateEntity, OrgUnitEntity, StatisticItem } from 'models'

const initialState: DistributeStateEntity = {
  statistic_items: {}
}
const slice = createSlice({
  name: 'DISTRIBUTE_STATE',
  initialState,
  reducers: {
    setFocusStore: (state, { payload }: PayloadAction<OrgUnitEntity | undefined>) => {
      state.focus_store = payload
    },
    addStatistic: (state, { payload }: PayloadAction<StatisticItem[]>) => {
      payload.forEach((item) => {
        state.statistic_items[item.id] = item
      })
    }
  }
})

export const { actions: distributeActions, reducer: distributeReducer } = slice
