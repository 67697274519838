/* eslint-disable consistent-return */
import { ImportExcelConfig } from 'models/redux/import_excel'
import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'hooks'
import { showApiError, showToast } from 'components'
import { instanceAxios } from 'utils'
import Api from 'services'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { FileInput } from './file_input'
import { Data } from './data'
import { Statistic } from './statistic'
import { ColumnSelect } from './column_select'
import { TemplateFile } from './template_file'
import { importExcelActions } from 'store/slices/import_excel'
import { AttachmentEntity } from 'models'
import { images } from 'assets'
import { ImportedFile } from './imported_files'

interface Props {
  config: ImportExcelConfig
}
export function ExcelImport({ config }: Props) {
  const { submit_request } = config ?? {}
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(importExcelActions.setConfig(config))
  }, [config.template_code, dispatch])

  const { template_code, only_preview } = config
  const getExcelTemplate = useCallback(async () => {
    try {
      if (!template_code) {
        return
      }
      const res: any = await Api.excel_template.show(template_code)
      dispatch(importExcelActions.setTemplate(res?.data?.data))
    } catch (e) {}
  }, [dispatch, template_code])

  useEffect(() => {
    getExcelTemplate()
  }, [getExcelTemplate])

  const handleImport = useCallback(
    async (file: any, preview: boolean) => {
      if (!submit_request || !file) {
        return
      }
      const { url, preview_param, params, onComplete } = submit_request
      try {
        showToast({
          content: 'Đang tải tệp lên để phân tích, vui lòng chờ...',
          type: 'info'
        })
        if (!config?.submit_request) {
          return
        }

        const form = new FormData()
        form.append('file', file)
        if (preview_param) {
          form.append(preview_param, `${preview}`)
        }
        for (const key of Object.keys(params)) {
          form.append(key, params[key as keyof typeof params])
        }

        instanceAxios
          .post(url, form, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then((res) => {
            if (preview) {
              const list = res?.data?.data ?? []
              dispatch(importExcelActions.setData(list as any[]))
            } else {
              showToast({
                content: 'Nhập thành công',
                type: 'success'
              })
              dispatch(importExcelActions.setData([]))
              dispatch(importExcelActions.setFile(undefined))
              onComplete?.()
            }
          })
          .catch((e) => {
            showApiError(e)
          })
      } catch (e) {}
    },
    [config?.submit_request, dispatch, submit_request]
  )
  return (
    <Stack sx={{ maxWidth: '1200px' }}>
      <Grid container columnSpacing={1}>
        <Grid item xs={2} sx={{}}>
          <Stack sx={{ alignItems: 'center' }}>
            <FileInput
              onSelectFile={(file) => {
                handleImport(file, true)
                dispatch(importExcelActions.setFile(file))
              }}
            />
            <Box sx={{ alignSelf: 'center', my: 0.5 }}>
              <TemplateFile />
            </Box>
            <ColumnSelect />
            <ImportedFile data={config.imported_file ?? []} />
          </Stack>
        </Grid>
        <Grid item xs={10} sx={{}}>
          <Stack
            sx={{
              pl: 2
            }}
          >
            <Statistic
              only_preview={only_preview}
              onImport={(file) => {
                handleImport(file, false)
              }}
            />
            <Data />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}
