import { AreaEntity } from './area'
import { AttachmentEntity } from './attachment'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'
import { TemplateEntity } from './template'
import { UserEntity } from './user_portal'

export enum EnumCampaignType {
  Format = 'format',
  Tokyolife = 'tokyolife'
}
export interface CampaignEntity extends EntityBase {
  name: string
  type: EnumCampaignType
  guide: string
  description: string
  slug: string
  created_by: UserEntity
  customer_count: number
  customer_contact_done: number
  areas: AreaEntity[]
  customer_files?: AttachmentEntity[]
  user_files?: AttachmentEntity[]
  whitelist_users?: UserEntity[]
  whitelist_units?: OrgUnitEntity[]
  created_at: string
  templates: TemplateEntity[]
}

export const CampaignTypeLabel: Record<EnumCampaignType, string> = {
  [EnumCampaignType.Format]: 'Format',
  [EnumCampaignType.Tokyolife]: 'Tokyolife'
}
