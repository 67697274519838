import { EntityBase } from '../entity'
import { PermissionEntity } from './permission'

export interface GroupPermissionEntity extends EntityBase {
  name: string
  role?: EnumDefaultRole
  description: string
  permissions?: PermissionEntity[]
}

export enum EnumDefaultRole {
  ADMIN = 1,
  BUSINESS_MANAGER = 2,
  STORE_MANAGER = 3,
  STORE_EMPLOYEE = 4,
  CUSTOMER_SERVICE = 5
}

export const DefaultRoleLabel: Record<EnumDefaultRole, string> = {
  [EnumDefaultRole.ADMIN]: 'Quản trị viên',
  [EnumDefaultRole.BUSINESS_MANAGER]: 'Quản lý/ giám sát kinh doanh',
  [EnumDefaultRole.STORE_MANAGER]: 'Quản lý cửa hàng',
  [EnumDefaultRole.STORE_EMPLOYEE]: 'Nhân viên cửa hàng',
  [EnumDefaultRole.CUSTOMER_SERVICE]: 'Dịch vụ khách hàng'
}
