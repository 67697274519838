// ==============================|| THEME CONFIG  ||============================== //

import { env } from 'process'

const config = {
  defaultPath: '/',
  // fontFamily: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
  fontFamily: '"-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif"',
  i18n: 'en',
  miniDrawer: false,
  container: true,
  // mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
}

export default config
export const drawerWidth = 260
export const headerHeight = 65

export * from './env'
