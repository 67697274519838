/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface FormStateEntity {
  data_forms: Record<string, any>
  error_forms: Record<string, any>
}

const initialState: FormStateEntity = {
  data_forms: {},
  error_forms: {}
}
const slice = createSlice({
  name: 'FORM_STATE',
  initialState,
  reducers: {
    //  Set up
    setFormData: (state, { payload }: PayloadAction<{ form_id: string; data: any }>) => {
      state.data_forms[payload.form_id] = payload.data
    },
    setErrors: (state, { payload }: PayloadAction<{ form_id: string; error: any }>) => {
      state.error_forms[payload.form_id] = payload.error
    }
  }
})
export const { actions: formActions, reducer: formReducer } = slice
